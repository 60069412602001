html {
  height: 100%;
}

body {
  margin: 0;
  height: 100%;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.loader {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9;
  background: rgba(255, 255, 255, 0.7);
  transition: all 0.5s cubic-bezier(0, 1.12, 0.99, -0.07) 0s;
  opacity: 1;
  
}
.loader > div {
  width: 120px;
  height: 120px;
  background-image: url(loading.gif);
  background-size: 120px;
  background-repeat: no-repeat;
  background-position: center;
}
.loader.loader-disabled {
  transition: all 0 ease 0s;
}

.form-control{
  margin: 2px;
  max-width: calc(100% - 4px)
}